export const addNonBreakableSpaceBehindSingleChar = (text: string) => {
    const words = text.split(" ");
    return words
        .map((w, i, arr) => {
            if (i === arr.length - 1) return w;
            w = w.length === 1 ? `${w}&nbsp;` : `${w} `;
            return w;
        })
        .join("");
};

export const isTextClamped = (elm: HTMLElement) => elm.scrollHeight > elm.clientHeight;

export const stringToHash = (text: string | undefined) =>
    text &&
    text
        .replaceAll(/[.,;:'"?!)(*&€ %$#@[\]{})\-_]+/gi, "-")
        .toLowerCase()
        .normalize("NFKD")
        .replaceAll(/[\u0300-\u036F]/gi, "")
        .replaceAll(/ł/gi, "l");
