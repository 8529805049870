import "vuetify/styles";
import { blue, cyan, purple, red } from "vuetify/util/colors";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import {
    mdiAccount,
    mdiAlertCircleOutline,
    mdiArrowBottomRight,
    mdiArrowLeftThin,
    mdiArrowRightThin,
    mdiArrowTopRight,
    mdiArrowUp,
    mdiClose,
    mdiEmail,
    mdiFullscreen,
    mdiMagnify,
    mdiMapMarker,
    mdiMenu,
    mdiMenuRight,
    mdiPhone,
    mdiStar,
    mdiStethoscope,
    mdiPlus
} from "@mdi/js";
import { VBtn } from "vuetify/components/VBtn";

export default defineNuxtPlugin((app) => {
    const vuetify = createVuetify({
        ssr: true,
        theme: {
            defaultTheme: "customTheme",
            themes: {
                customTheme: {
                    colors: {
                        "text-red": red.lighten1,
                        "purple-light": purple.lighten4,
                        "purple-dark": "#000662",
                        "blue-light": blue.lighten5,
                        "red-light": red.lighten5,
                        purple: "#9000FF",
                        blue: "#0081C7",
                        cyan: cyan.lighten1,
                        medica: "#006EE1",
                        dental: "#33A83F",
                        estetis: "#A68989",
                        diag: "#07A2D3"
                    }
                }
            }
        },
        aliases: {
            AccentBtn: VBtn
        },
        defaults: {
            global: {
                ripple: false
            },
            VCard: {
                elevation: 2
            },
            VBtn: {
                style: [
                    {
                        textTransform: "lowercase"
                    }
                ],
                class: ["text-capitalize", "text-button", "font-weight-regular"]
            },
            AccentBtn: {
                rounded: true,
                variant: "elevated",
                minWidth: "10rem",
                class: ["linear-gradient-btn", "text-capitalize", "text-button", "font-weight-regular"],
                color: "deep-purple-darken-4"
            }
        },
        icons: {
            defaultSet: "mdi",
            aliases: {
                ...aliases,
                left: mdiArrowLeftThin,
                right: mdiArrowRightThin,
                up: mdiArrowUp,
                close: mdiClose,
                email: mdiEmail,
                marker: mdiMapMarker,
                menu: mdiMenu,
                phone: mdiPhone,
                stethoscope: mdiStethoscope,
                star: mdiStar,
                menuRight: mdiMenuRight,
                alert: mdiAlertCircleOutline,
                fullscreen: mdiFullscreen,
                arrowTopRight: mdiArrowTopRight,
                arrowBottomRight: mdiArrowBottomRight,
                magnify: mdiMagnify,
                account: mdiAccount,
                plus: mdiPlus
            },
            sets: {
                mdi
            }
        }
    });
    app.vueApp.use(vuetify);
});
