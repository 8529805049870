
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as contactW7X2MCpnfEMeta } from "/home/vsts/work/1/s/pages/contact.vue?macro=true";
import { default as costkA21upItATMeta } from "/home/vsts/work/1/s/pages/cost.vue?macro=true";
import { default as faqqsweyF5Ao6Meta } from "/home/vsts/work/1/s/pages/faq.vue?macro=true";
import { default as galleryCMwmF7mZMKMeta } from "/home/vsts/work/1/s/pages/gallery.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as _91id_93m9o3D8fo0jMeta } from "/home/vsts/work/1/s/pages/service/[main_name]/[name]/[id].vue?macro=true";
import { default as _91id_93JPspIV8HdrMeta } from "/home/vsts/work/1/s/pages/specialization/[name]/[id].vue?macro=true";
import { default as _91id_93snB9XxRZgRMeta } from "/home/vsts/work/1/s/pages/staff/[name]/[id].vue?macro=true";
import { default as indexJvNCOrSENyMeta } from "/home/vsts/work/1/s/pages/staff/index.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    meta: contactW7X2MCpnfEMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/contact.vue")
  },
  {
    name: "cost",
    path: "/cost",
    meta: costkA21upItATMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/cost.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqqsweyF5Ao6Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/faq.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryCMwmF7mZMKMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/gallery.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexegOWm4p6gtMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  },
  {
    name: "service-main_name-name-id",
    path: "/service/:main_name()/:name()/:id()",
    meta: _91id_93m9o3D8fo0jMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/service/[main_name]/[name]/[id].vue")
  },
  {
    name: "specialization-name-id",
    path: "/specialization/:name()/:id()",
    meta: _91id_93JPspIV8HdrMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/specialization/[name]/[id].vue")
  },
  {
    name: "staff-name-id",
    path: "/staff/:name()/:id()",
    meta: _91id_93snB9XxRZgRMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/staff/[name]/[id].vue")
  },
  {
    name: "staff",
    path: "/staff",
    meta: indexJvNCOrSENyMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/staff/index.vue")
  }
]